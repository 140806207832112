import {request} from "./request";

export function getList(data) {
    return request({
        method: 'post',
        url: '/message/list',
        data:{
            "currentPage": data.currentPage,
            "pageSize": data.pageSize
        }
    })
}

export function sendMsg(content) {
    return request({
        method: 'post',
        url: '/message',
        data:{
            "content": content
        }
    })
}

export function reply(data) {
    return request({
        method: 'post',
        url: '/message/replay',
        data:{
            "id": data.id,
            "replay": data.content
        }
    })
}
export function handle(data) {
    return request({
        method: 'post',
        url: '/message/handle',
        data:{
            "id": data.id,
            "replay": data.content
        }
    })
}

export function deleteBatch(ids) {
    return request({
        method: 'delete',
        url: '/message/delete/batch',
        data:{
            "ids": ids
        }
    })
}

// 获取柱状图数据
export function findClassData(){
    return request({
        method:'post',
        url:'/admin/class/findClassData'
    })
}
// 获取饼状图数据
export function findClassPieDate(){
    return request({
        method:'post',
        url:'/admin/class/findClassPieDate'
    })
}


